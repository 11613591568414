* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.site-container {
  text-align: center;
}

html {
  font-size: 14px;
}

body {
  line-height: 1.5;
  color: black;
  margin: 0;
}

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

/* Css for Navbar Component  */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 5rem;
  background-color: rgb(0, 0, 0);
}

.camera {
  margin-right: 5rem;
}

.camera:hover {
  cursor: pointer;
}

nav h1 {
  margin-left: 5rem;
  cursor: pointer;
  color: rgb(229, 226, 237);
  font-size: 2.2rem;
}

.post-img {
  width: 100%;
  border-radius: 5px;
}

/* Css for Card Component  */
.card {
  width: 35rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  margin: 5px;
  padding: 8px;
}

.add-post-container {
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #ddd;
  opacity: 0.95;
  outline: none;
  width: 40rem;
  height: 20rem;
  margin: auto;
  margin-top: 10vh;
  border: 1px solid black;
  padding: 10px;
}

.post-form {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.description {
  width: 100%;
  height: 25px;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #ddd;
  opacity: 0.95;
  outline: none;
}

.description>input {
  height: 35px;
  padding: 10px;
}

.Author-location {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.Author-location>input {
  width: 48%;
  height: 30px;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #ddd;
  opacity: 0.95;
  outline: none;
  padding: 10px;
}

.post-submit {
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #ddd;
  opacity: 0.95;
  outline: none;
  padding: 10px 30px;
  cursor: pointer;
}

.name {
  margin-left: 10px;
  text-align: start;
}

.name h3:hover {
  cursor: pointer;
}

.more {
  font-size: 1.7rem;
  margin-right: 10px;
}

.more:hover {
  cursor: pointer;
}

.head {
  display: flex;
  justify-content: space-between;
}

.foot {
  display: flex;
  justify-content: space-between;
  margin-left: 6px;
  margin-right: 6px;
  align-items: center;
}

.foot span {
  font-size: 2rem;
  margin-right: 5px;
  font-weight: bold;
}

.foot span:hover {
  cursor: pointer;
}

.like-content {
  margin-left: 7px;
  text-align: start;
  margin-bottom: 1.7rem;
  margin-top: 0.5rem;
}

.content {
  margin-top: 7px;
  padding-right: 4px;
  word-break: break-word;
}

/* Css for Post View  */
.parent-css {
  display: flex;
  width: 100%;
  height: 95vh;
  justify-content: center;
  align-items: center;
}

.child-css {
  display: flex;
  width: 80%;
  height: 75vh;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.right-css {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: darkcyan;
  width: 50%;
  height: 75vh;
  justify-content: center;
}

.right-css button {
  border: 3px solid darkcyan;
  padding: 7px 15px;
  border-radius: 10px;
  background-color: white;
  cursor: pointer;
  margin-top: 5px;
}

.right-css p {
  font-size: 3rem;
}

.left-css {
  width: 30%;
  height: 60vh;
}

/* ............................................................... */

div.upload {
  background-color: #425f9c;
  border: 1px solid #ddd;
  border-radius: 5px;
  display: inline-block;
  padding: 3px 40px 3px 3px;
  position: relative;
  height: 45px;
  width: 100%;
}

div.upload:hover {
  opacity: 0.95;
  cursor: pointer;
}

div.upload input[type="file"] {
  cursor: pointer;
  border: none;
  outline: none;
  color: white;
  display: input-block;
  width: 100%;
  height: 30px;
  cursor: pointer;
  position: absolute;
  left: 0;
  margin-top: 8px;
  margin-left: 5px;
}

@media only screen and (max-width: 720px) {
  .left-css {
    display: none;
  }

  .card {
    width: 90%;
  }

  .child-css {
    width: 90%;
  }

  .parent-css {
    height: 80vh;
  }

  .add-post-container {
    width: 95%;
  }

  .camera {
    margin-right: 1rem;
  }

  nav h1 {
    margin-left: 1rem;
  }
}

@media only screen and (max-width: 420px) {
  .left-css {
    display: none;
  }

  .card {
    width: 95%;
  }

  .child-css {
    width: 95%;
  }
}